import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { formatTimestamp } from "../utils/dates"
import kebabCase from "lodash/kebabCase"
import { DiscussionEmbed } from "disqus-react";
import Helmet from 'react-helmet';

const Tag = ({tag}) => {
  return (
    <Link
      to={`/tags/${kebabCase(tag)}`}
      className="-mb-8 prose-underline-none inline-block py-0 px-1 bg-gray-200 hover:bg-gray-100"
    >
      {tag}
    </Link>
  )
}

export default function BlogPost({ data, pageContext, location }) {
  const { previousPost, nextPost } = pageContext
  const post = data.markdownRemark
  const image = getImage(post.frontmatter.featured);

  console.log(pageContext);
  
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: post.fields.slug }
  }

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={image}
        pathname={location.pathname}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(pageContext.recipeSchema, null, 2)}</script>
      </Helmet>
      <div className="flex-grow">
        <div className="max-w-prose container mx-auto px-4 -mt-8 mb-2 flex">
          <Link className="mb-4 inline-block px-4 py-2 bg-gray-100" to="/">
            ← Back to Recipes
          </Link>
        </div>
        <article className="prose mx-auto px-4 mb-16">
          <h1>{post.frontmatter.title}</h1>
          <div className="font-mono text-sm mb-2 text-gray-500">
            POSTED: {formatTimestamp(post.frontmatter.date)}
          </div>
          <div className="font-mono text-sm mb-2 text-gray-500 space-x-2">
            TAGGED: {post.frontmatter.tags.map(tag => <Tag tag={tag}/>)}
          </div>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
      </div>
      <nav className="bg-gray-100">
        <div className="max-w-prose container flex justify-between items-center px-4 py-4 mx-auto">
          <div className="flex items-center" style={{ maxWidth: "45%" }}>
            {previousPost ? (
              <Link
                to={`/recipes${previousPost.slug}`}
                rel="prev"
                style={{ marginRight: 20 }}
              >
                <div className="flex items-center justify-start text-left">
                  <span className="mr-4">←</span>
                  <span>{previousPost.title}</span>
                </div>
              </Link>
            ) : (
              <div className="opacity-40 text-left">No older recipes...</div>
            )}
          </div>
          <div className="flex items-center" style={{ maxWidth: "45%" }}>
            {nextPost ? (
              <Link to={`/recipes${nextPost.slug}`} rel="next">
                <div className="flex items-center justify-end text-right">
                  <span>{nextPost.title}</span>
                  <span className="ml-4">→</span>
                </div>
              </Link>
            ) : (
              <div className="opacity-40 text-right">No newer recipes...</div>
            )}
          </div>
        </div>
      </nav>
      <div className="max-w-prose mx-auto container my-4 px-4"><DiscussionEmbed {...disqusConfig}/></div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        featured {
          childImageSharp {
            gatsbyImageData(width:1024)
          }
        }
        tags
        date
      }
      fields {
        slug
      }
    }
  }
`
